import React, { Component } from 'react'
import { Col, Row, Card, CardBody } from 'reactstrap'

// import PopupIcon1 from '../../images/popup-types/icon-exit-popups.png'
// import PopupIcon2 from '../../images/popup-types/icon-fullscreen-overlays.png'
// import PopupIcon3 from '../../images/popup-types/icon-spin-the-wheel.png'
// import PopupIcon4 from '../../images/popup-types/icon-countdown-timers.png'
// import PopupIcon5 from '../../images/popup-types/icon-floating-bars.png'
// import PopupIcon6 from '../../images/popup-types/icon-slide-in.png'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// class HomePopupTypes extends Component {
//     render() {
//         return (
//             <div className="home-popup-types-section">
//                 <div className="container">
//                     <div className="mb-5 text-center hero-content wow fadeIn">
//                         <h2>Different Types of Website Popups. To Double <br/> <span className="style-highlight"> Conversions and Growth. 
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
//                         <p>Collect your captured email leads in one place. Seamlessly integrate with some of the best ESPs and webhooks in the industry. And carry out multiple actions in autopilot.</p>
//                     </div>
//                     <Row>
//                         <Col md="4">
//                             <Card className="text-center">
//                                 <CardBody>
//                                     <img src={PopupIcon1} alt="" />
//                                     <h4>Exit Popups</h4>
//                                     <p>Sway visitors at the last moment. Run exit popup campaigns to display captivating deals &amp; offers.</p>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                         <Col md="4">
//                             <Card className="text-center">
//                                 <CardBody>
//                                     <img src={PopupIcon2} alt="" />
//                                     <h4>Fullscreen Overlays</h4>
//                                     <p>Grab undivided user focus & attention. Use fullscreen overlay popups to convey more to the web audience.</p>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                         <Col md="4">
//                             <Card className="text-center">
//                                 <CardBody>
//                                     <img src={PopupIcon3} alt="" />
//                                     <h4>Spin the Wheel</h4>
//                                     <p>More engagement. More conversions. Use gamification popups to display coupons and increase sales.</p>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Col md="4">
//                             <Card className="text-center">
//                                 <CardBody>
//                                     <img src={PopupIcon4} alt="" />
//                                     <h4>Countdown Timers</h4>
//                                     <p>Create FOMO and increase store sales using countdown timers. Boost sales during seasonal/holiday sales.</p>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                         <Col md="4">
//                             <Card className="text-center">
//                                 <CardBody>
//                                     <img src={PopupIcon5} alt="" />
//                                     <h4>Floating Bars</h4>
//                                     <p>Drive conversions the subtle way. Use floating bars to capture leads while not taking much screen space.</p>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                         <Col md="4">
//                             <Card className="text-center">
//                                 <CardBody>
//                                     <img src={PopupIcon6} alt="" />
//                                     <h4>Slide-in Popup</h4>
//                                     <p>Optinly lets you display Slide-in popups on the left/right of your screen and floating bars at the top/bottom of your screen.</p>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                     </Row>
//                 </div>
//             </div>
//         )
//     }
// }

const HomePopupTypes =()=>{

const data = useStaticQuery(graphql`
query HomePopupImageQuery {

    PopupIcon1:  file(absolutePath: {regex: "/images/popup-types/"}, 
    name: {eq: "icon-exit-popups"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE,height:140 )
    }
  }

  PopupIcon2:  file(absolutePath: {regex: "/images/popup-types/"}, 
    name: {eq: "icon-fullscreen-overlays"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, height:140)
    }
  }

  PopupIcon3:  file(absolutePath: {regex: "/images/popup-types/"}, 
    name: {eq: "icon-spin-the-wheel"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, height:140)
    }
  }

  PopupIcon4:  file(absolutePath: {regex: "/images/popup-types/"}, 
    name: {eq: "icon-countdown-timers"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE,height:140 )
    }
  }

  PopupIcon5:  file(absolutePath: {regex: "/images/popup-types/"}, 
    name: {eq: "icon-floating-bars"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE,height:140 )
    }
  }

  PopupIcon6:  file(absolutePath: {regex: "/images/popup-types/"}, 
    name: {eq: "icon-slide-in"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE,height:140 )
    }
  }

}
`)

const PopupIcon1 = getImage(data.PopupIcon1)
const PopupIcon2 = getImage(data.PopupIcon2)
const PopupIcon3 = getImage(data.PopupIcon3)
const PopupIcon4 = getImage(data.PopupIcon4)
const PopupIcon5 = getImage(data.PopupIcon5)
const PopupIcon6 = getImage(data.PopupIcon6)


    return (
        <div className="home-popup-types-section">
            <div className="container">
                <div className="mb-5 text-center hero-content wow fadeIn">
                    <h2>Different Types of Website Popups. To Double <br/> <span className="style-highlight"> Conversions and Growth. 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                    <p>Collect your captured email leads in one place. Seamlessly integrate with some of the best ESPs and webhooks in the industry. And carry out multiple actions in autopilot.</p>
                </div>
                <Row>
                    <Col md="4">
                        <Card className="text-center">
                            <CardBody>
                                <GatsbyImage image={PopupIcon1}alt="" />
                                {/* <img src={PopupIcon1} alt="" /> */}
                                <h4>Exit Popups</h4>
                                <p>Sway visitors at the last moment. Run exit popup campaigns to display captivating deals &amp; offers.</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="text-center">
                            <CardBody>
                            <GatsbyImage image={PopupIcon2}alt="" />

                                {/* <img src={PopupIcon2} alt="" /> */}
                                <h4>Fullscreen Overlays</h4>
                                <p>Grab undivided user focus & attention. Use fullscreen overlay popups to convey more to the web audience.</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="text-center">
                            <CardBody>
                            <GatsbyImage image={PopupIcon3}alt="" />

                                <h4>Spin the Wheel</h4>
                                <p>More engagement. More conversions. Use gamification popups to display coupons and increase sales.</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <Card className="text-center">
                            <CardBody>
                            <GatsbyImage image={PopupIcon4}alt="" />

                                <h4>Countdown Timers</h4>
                                <p>Create FOMO and increase store sales using countdown timers. Boost sales during seasonal/holiday sales.</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="text-center">
                            <CardBody>
                            <GatsbyImage image={PopupIcon5}alt="" />

                                <h4>Floating Bars</h4>
                                <p>Drive conversions the subtle way. Use floating bars to capture leads while not taking much screen space.</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="text-center">
                            <CardBody>
                            <GatsbyImage image={PopupIcon6}alt="" />

                                <h4>Slide-in Popup</h4>
                                <p>Optinly lets you display Slide-in popups on the left/right of your screen and floating bars at the top/bottom of your screen.</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default HomePopupTypes
