import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import PopupIcon1 from '../../images/use-case/feature-icons/grab-visitor-attention.svg'
import PopupIcon2 from '../../images/use-case/feature-icons/increase-conversion-rate.svg'
import PopupIcon3 from '../../images/use-case/feature-icons/collect-feedbacks.svg'
import PopupIcon6 from '../../images/use-case/feature-icons/grow-your-email-list.svg'
import PopupIcon7 from '../../images/use-case/feature-icons/increase-cart-value.svg'
import PopupIcon9 from '../../images/use-case/feature-icons/drag-drop-builder.svg'


const homeFeatures = () => {
    return (
        <div className="types-of-cards pt-5">
            <div className="container">
                <div className="text-center mb-5">
                    <h2> How Optinly Can Help Grow Your Business</h2>
                </div>
                <Row>
                    <Col md="4">
                        <div className="card p-2 popup-card text-center">
                            <div className="text-center card-image my-3">
                                <img src={PopupIcon9} alt="Drag and Drop Builder" />
                            </div>
                            <div className="text-center card-title">
                                <h3> Drag {"&"} Drop Builder</h3>
                            </div>
                            <div className="card-body">
                                Create endless types of pop-ups with our drag and drop popup builder.
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="card p-2 popup-card text-center">
                            <div className="text-center card-image my-3">
                                <img src={PopupIcon1} alt="Grab Web Visitor Attention" />
                            </div>
                            <div className="text-center card-title">
                                <h3> Grab Visitor's Attention</h3>
                            </div>
                            <div className="card-body">
                                Show visitors what they need - at the right time and at the right place.
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="card p-2 popup-card text-center">
                            <div className="text-center card-image my-3">
                                <img src={PopupIcon2} alt="Increase Conversion Rate" />
                            </div>
                            <div className="text-center card-title">
                                <h3> Increase Conversion Rate</h3>
                            </div>
                            <div className="card-body">
                                Make visitors take desired actions and achieve your business goals.
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="card p-2 popup-card text-center">
                            <div className="text-center card-image my-3">
                                <img src={PopupIcon3} alt="Collect Customer Feedbacks" />
                            </div>
                            <div className="text-center card-title">
                                <h3> Collect Feedback</h3>
                            </div>
                            <div className="card-body">
                                Know what website visitors think about your brand & your store.
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="card p-2 popup-card text-center">
                            <div className="text-center card-image my-3">
                                <img src={PopupIcon6} alt="Grow Your Email List" />
                            </div>
                            <div className="text-center card-title">
                                <h3> Grow Your Email List</h3>
                            </div>
                            <div className="card-body">
                                Capture more visitors and grow your email list by displaying popups.
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="card p-2 popup-card text-center">
                            <div className="text-center card-image my-3">
                                <img src={PopupIcon7} alt="Increase Cart Value" />
                            </div>
                            <div className="text-center card-title">
                                <h3> Increase Cart Value</h3>
                            </div>
                            <div className="card-body">
                                Double your store's revenue by offering them tailored & personalized discounts.
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="elementor-shape elementor-shape-bottom" data-negative="false">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path className="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                <path className="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                <path className="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                </svg> 
            </div>
        </div>
    )
}

export default homeFeatures
