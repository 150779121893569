import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'

// import FeatureIcon1 from '../../images/popup-features/email-list.png'
// import FeatureIcon2 from '../../images/popup-features/increase-sale.png'
// import FeatureIcon3 from '../../images/popup-features/promote.png'
// import FeatureIcon4 from '../../images/popup-features/survey.png'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// export class HomePopupFeatures extends Component {
//     render() {
//         return (
//             <div className="home-popup-features-section">
//                 <div className="container">
//                     <Row className="align-items-center">
//                         <Col md="6">
//                             <div className="hero-content wow fadeIn">
//                                 <h2>Goal-Based Website Popups.<br/> For Every Existing <br/> <span className="style-highlight"> Business Goals. 
//                                 <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
//                                 <p>Not all businesses want to grow their email list. Some want to increase eCommerce sales while others would want to reduce cart abandonment. With Optinly on-board, no goal is hard to achieve. Whether it’s growing your email list or increasing your sales, Optinly does the job pretty well – every single time.</p>
//                             </div>
//                         </Col>
//                         <Col md="6">
//                             <Row>
//                                 <Col md="6">
//                                     <Card className="mb-4 text-center">
//                                         <CardBody>
//                                             <img src={FeatureIcon1} alt="" />
//                                             <h5>Grow Your Email List</h5>
//                                             <p>Capture high potential leads and grow your email list on the go. Create lead generation popup campaigns using pre-existing templates.</p>
//                                         </CardBody>
//                                     </Card>
//                                 </Col>
//                                 <Col md="6">
//                                     <Card className="mb-4 text-center">
//                                         <CardBody>
//                                             <img src={FeatureIcon2} alt="" />
//                                             <h5>Increase eCommerce Sales</h5>
//                                             <p>Grow sales figures 10x times using Optinly popup builder. Promote special offers, reduce cart abandonment and more.</p>
//                                         </CardBody>
//                                     </Card>
//                                 </Col>
//                                 <Col md="6">
//                                     <Card className="mb-4 text-center">
//                                         <CardBody>
//                                             <img src={FeatureIcon3} alt="" />
//                                             <h5>Promote Special Offers</h5>
//                                             <p>Boost your Cyber Monday/Holiday Seasonal Sales. Use countdown timer and spin wheel popups to increase engagement and sales.</p>
//                                         </CardBody>
//                                     </Card>
//                                 </Col>
//                                 <Col md="6">
//                                     <Card className="mb-4 text-center">
//                                         <CardBody>
//                                             <img image={FeatureIcon4} alt="" />
//                                             <h5>Conduct Surveys</h5>
//                                             <p>Know what people’s take is on your business. Collect audience feedback using survey popup templates and enhance customer experience.</p>
//                                         </CardBody>
//                                     </Card>
//                                 </Col>
//                             </Row>
//                         </Col>
//                     </Row>
//                 </div>
//             </div>
//         )
//     }
// }


const  HomePopupFeatures=()=>{
   
    const data = useStaticQuery(graphql`
    
    query HomePopupFeaturesImageQuery {
        
        FeatureIcon1: file(absolutePath: {regex: "/images/popup-features/"}, 
          name: {eq: "email-list"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE,height:60 )
          }
        }

        FeatureIcon2: file(absolutePath: {regex: "/images/popup-features/"}, 
          name: {eq: "increase-sale"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, height:60)
          }
        }

        FeatureIcon3: file(absolutePath: {regex: "/images/popup-features/"}, 
          name: {eq: "promote"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, height:60)
          }
        }

        FeatureIcon4: file(absolutePath: {regex: "/images/popup-features/"}, 
          name: {eq: "survey"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE,height:60 )
          }
        }
      }
      
    `)

    const FeatureIcon1 = getImage(data.FeatureIcon1)
    const FeatureIcon2 = getImage(data.FeatureIcon2)
    const FeatureIcon3 = getImage(data.FeatureIcon3)
    const FeatureIcon4 = getImage(data.FeatureIcon4)

   
    return (
        <div className="home-popup-features-section">
            <div className="container">
                <Row className="align-items-center">
                    <Col md="6">
                        <div className="hero-content wow fadeIn">
                            <h2>Goal-Based Website Popups.<br/> For Every Existing <br/> <span className="style-highlight"> Business Goals. 
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                            <p>Not all businesses want to grow their email list. Some want to increase eCommerce sales while others would want to reduce cart abandonment. With Optinly on-board, no goal is hard to achieve. Whether it’s growing your email list or increasing your sales, Optinly does the job pretty well – every single time.</p>
                        </div>
                    </Col>
                    <Col md="6">
                        <Row>
                            <Col md="6">
                                <Card className="mb-4 text-center">
                                    <CardBody>
                                        <GatsbyImage image={FeatureIcon1} alt="" />
                                        <h5>Grow Your Email List</h5>
                                        <p>Capture high potential leads and grow your email list on the go. Create lead generation popup campaigns using pre-existing templates.</p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card className="mb-4 text-center">
                                    <CardBody>
                                        <GatsbyImage image={FeatureIcon2} alt="" />
                                        <h5>Increase eCommerce Sales</h5>
                                        <p>Grow sales figures 10x times using Optinly popup builder. Promote special offers, reduce cart abandonment and more.</p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card className="mb-4 text-center">
                                    <CardBody>
                                        <GatsbyImage image={FeatureIcon3} alt="" />
                                        <h5>Promote Special Offers</h5>
                                        <p>Boost your Cyber Monday/Holiday Seasonal Sales. Use countdown timer and spin wheel popups to increase engagement and sales.</p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card className="mb-4 text-center">
                                    <CardBody>
                                        <GatsbyImage image={FeatureIcon4} alt="" />
                                        <h5>Conduct Surveys</h5>
                                        <p>Know what people’s take is on your business. Collect audience feedback using survey popup templates and enhance customer experience.</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
    
}

export default HomePopupFeatures
