import React from 'react'
import Layout from '../components/layout'
import Metatags from "../components/Metatags"
//import home page components
import GetStarted from '../components/getstarted'
// import Integrations from '../constants/HomePage/Integrations'
import HomeBanner from '../constants/Home/Banner';
import HomeIntegrationCompanies from '../constants/Home/IntegrationCompanies';
import HomeCounter from '../constants/Home/homeCounter';
import HomePopupFeatures from '../constants/Home/HomePopupFeatures';
import HomePopupTypes from '../constants/Home/HomePopupTypes';
import HomePopupTemplates from '../constants/Home/HomePopupTemplates';
import HomePopupClients from '../constants/Home/HomePopupClients';
import HomePopupTestimonials from '../constants/Home/HomePopupTestimonials';
import HomeFeatures from '../constants/Home/homeFeatures';
// import SignupForm from "../components/SignupForm"
// import {graphql} from "gatsby";
// import HomeUseCase from "../constants/HomePage/homeusecase"
import CookieConsent from "../components/cookieconsent";

import '../assets/css/index.css'

const IndexPage = () => (
    <Layout className="home-page">
        
        <Metatags
            title="Goal-Based Drag-and-Drop Popup Builder for Websites"
            description = "Boost engagement with dynamic pop-ups: spin-wheels, exit-intent pop-ups, and more. Boost sales, reduce cart abandonment, & grow your email list with Optinly."
            keywords= {[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url = "https://www.optinly.com"
            pathname = "/"
        />

        <HomeBanner/>
        <HomeIntegrationCompanies />
        <HomeCounter />
        <HomeFeatures />
        <HomePopupFeatures />
        <HomePopupTypes />
        <HomePopupTestimonials />
        <HomePopupTemplates />
        <HomePopupClients />
        <GetStarted title="Start Growing Your Email List With Optinly Today" />
        {/* <SignupForm/> */}
        {/* <Integrations/> */}
        {/*<HomeUseCase/>*/}
        {/* <div className="row d-flex justify-content-center">
            <CookieConsent 
            className="container" style={{borderRadius:'5px' }}  
            buttonStyle={{color:"Black", fontWeight: "bolder",borderRadius:'5px'}}>
                <p>We use cookies to improve your experience on our website.</p>
            </CookieConsent>
        </div> */}
    </Layout>
);

export default IndexPage;
