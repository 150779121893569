import React, { Component } from 'react'
import SwiperSlider from '../../components/SwiperSliderTestimonial';

import SliderImg1 from '../../images/popup-testimonial/jack-armstrong.jpg';
import SliderImg2 from '../../images/popup-testimonial/raviprakash.jpg';
import SliderImg3 from '../../images/popup-testimonial/tgrgte.png';
import SliderImg4 from '../../images/popup-testimonial/tommmy.jpg';
import SliderImg5 from '../../images/popup-testimonial/weblink.jpg';

const Slides = [
    {
        cite: `
            <cite className="elementor-testimonial__cite"><span className="elementor-testimonial__name">Jack Armstrong</span><span className="elementor-testimonial__company"> - Air Pixels</span><span className="elementor-testimonial__title">Great product!</span></cite>
        `,
        content: `
            <div className="swiper-slide-contents">
            <div className="elementor-testimonial__text"> Just started using Optinly to maximize return on exit intent for users leaving my site. I easily installed a popup to display discount code and I really enjoy the banners in the analytics page. I highly recommend this great deal.</div>
            </div>`,
        img: SliderImg1
    },
    {
        cite: `
            <cite className="elementor-testimonial__cite"><span className="elementor-testimonial__name">RaviPrakash </span><span className="elementor-testimonial__title">Very important product for <br>pop-ups</span></cite>
        `,
        content: `
            <div className="swiper-slide-contents"><div className="elementor-testimonial__text">
            When combined with Switchy, Optinly is a neat and solid product via which you can create all kinds of popup to capture any kind of leads, up-sales and converting a lead to customer. Don’t miss out on this deal, Grab optinly at the earliest. </div>
            </div>`,
        img: SliderImg2
    },
    {
        cite: `
            <cite className="elementor-testimonial__cite"><span className="elementor-testimonial__name">Takeo</span><span className="elementor-testimonial__title">Fits our needs well</span></cite>
        `,
        content: `
            <div className="swiper-slide-contents"><div className="elementor-testimonial__text"> I've only been using Optinly for a couple of days, but I'm already in love with it!
Those of us who are dedicated to lead collection need tools like Optinly.
If you really want to grow business, please start to use Optinly right now! </div></div>`,
        img: SliderImg3
    },
    {
        cite: `
        <cite className="elementor-testimonial__cite"><span className="elementor-testimonial__name">Tommmy.q</span><span className="elementor-testimonial__company"> - The Derm Detective</span><span className="elementor-testimonial__title">Better Price Than OptinMonster For Sure</span></cite>
        `,
        content: `
            <div className="swiper-slide-contents"><div className="elementor-testimonial__text"> I've been using popups for email optins in the past and found out all these popup makers are expensive. Even I've refunded OptinMonster due to high cost. Then I found that Optinly offers similar features but with a better value, so I got an unlimited deal that should pay for itself in a few years </div></div>`,
        img: SliderImg4
    },
    {
        cite: `
            <cite className="elementor-testimonial__cite"><span className="elementor-testimonial__name">Weblink</span><span className="elementor-testimonial__title">Overall seems pretty good</span></cite>
        `,
        content: `
            <div className="swiper-slide-contents"><div className="elementor-testimonial__text">Haven't gotten to dive too deep into it yet, but seems pretty good overall. I currently use OptinMonster so it's definitely not as polished as that, but OptinMonster is so overpriced! So as long as it does the job, this will be the new go-to! Thanks for the being on Appsumo! </div></div>`,
        img: SliderImg5
    },
];

class HomePopupTestimonials extends Component {
    render() {
        return (
            <div className="home-popup-testimonial-section">
                <div className="elementor-shape elementor-shape-top" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path className="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path className="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path className="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg>
                </div>
                <div className="container">
                    <div className="hero-content wow fadeIn text-center mb-5">
                        <h2>See why <span className="style-highlight"> 5000+ customers 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span> love using Optinly</h2>
                        <p>Optinly leads the pack when it comes to capturing leads using popups and that’s why our customers love it.</p>
                    </div>
                    <div className="position-relative pt-3">
                        <SwiperSlider slidesPerView={3} slides={Slides} spaceBetween={40} slideDeley={5000} />
                    </div>
                </div>
            </div>
        )
    }
}

export default HomePopupTestimonials
