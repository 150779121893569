import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { FaArrowRight } from 'react-icons/fa';

// import PopupIcon1 from '../../images/popup-templates/template1.png'
// import PopupIcon2 from '../../images/popup-templates/template2.png'
// import PopupIcon3 from '../../images/popup-templates/template3.png'
// import PopupIcon4 from '../../images/popup-templates/template4.png'
// import PopupIcon5 from '../../images/popup-templates/template5.png'
// import PopupIcon6 from '../../images/popup-templates/template6.png'
// import PopupIcon7 from '../../images/popup-templates/template7.png'
// import PopupIcon8 from '../../images/popup-templates/template8.png'
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// class HomePopupTemplates extends Component {
//     render() {
//         return (
//             <div className="home-popup-templates-section">
//                 <div className="container">
//                     <div className="mb-5 text-center hero-content wow fadeIn">
//                         <h2>100+ Highly Responsive <span className="style-highlight"> Popup Templates
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
//                         <p>Take a look at what we’ve been building in-house. Captivating & visually appealing popup templates, all built for you!</p>
//                     </div>
//                     <Row className="popup-templates-container">
//                         <Col>
//                             <img src={PopupIcon1} alt="" />
//                         </Col>
//                         <Col>
//                             <img src={PopupIcon2} alt="" />
//                         </Col>
//                         <Col>
//                             <img src={PopupIcon3} alt="" />
//                         </Col>
//                         <Col>
//                             <img src={PopupIcon4} alt="" />
//                         </Col>
//                         <Col>
//                             <img src={PopupIcon5} alt="" />
//                         </Col>
//                         <Col>
//                             <img src={PopupIcon6} alt="" />
//                         </Col>
//                         <Col>
//                             <img src={PopupIcon7} alt="" />
//                         </Col>
//                         <Col>
//                             <img src={PopupIcon8} alt="" />
//                         </Col>
//                     </Row>
//                     <div className="my-4 text-center">
//                         <a className="btn btn-secondary" href="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" target="_blank">
//                             More Templates <FaArrowRight />
//                         </a>
//                     </div>
//                 </div>
//                 <div className="elementor-shape elementor-shape-bottom" data-negative="false">
//                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
//                     <path className="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
//                     <path className="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
//                     <path className="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
//                     </svg>
//                 </div>
//             </div>
//         )
//     }
// }

 const HomePopupTemplates =()=>{

const data = useStaticQuery(graphql`

query HomePopupTemplatesImageQuery {
    PopupIcon1: file(absolutePath: {regex: "/images/popup-features/"}, 
      name: {eq: "template1"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    PopupIcon2: file(absolutePath: {regex: "/images/popup-features/"}, 
      name: {eq: "template2"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    PopupIcon3: file(absolutePath: {regex: "/images/popup-features/"}, 
      name: {eq: "template3"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    PopupIcon4: file(absolutePath: {regex: "/images/popup-features/"}, 
      name: {eq: "template4"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    PopupIcon5: file(absolutePath: {regex: "/images/popup-features/"}, 
      name: {eq: "template5"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    PopupIcon6: file(absolutePath: {regex: "/images/popup-features/"}, 
      name: {eq: "template6"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    PopupIcon7: file(absolutePath: {regex: "/images/popup-features/"}, 
      name: {eq: "template7"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    PopupIcon8: file(absolutePath: {regex: "/images/popup-features/"}, 
      name: {eq: "template8"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }
  }
  

`)


const PopupIcon1 = getImage(data.PopupIcon1)
const PopupIcon2 = getImage(data.PopupIcon2)
const PopupIcon3 = getImage(data.PopupIcon3)
const PopupIcon4 = getImage(data.PopupIcon4)
const PopupIcon5 = getImage(data.PopupIcon5)
const PopupIcon6 = getImage(data.PopupIcon6)
const PopupIcon7 = getImage(data.PopupIcon7)
const PopupIcon8 = getImage(data.PopupIcon8)


    return (
        <div className="home-popup-templates-section">
            <div className="container">
                <div className="mb-5 text-center hero-content wow fadeIn">
                    <h2>100+ Highly Responsive <span className="style-highlight"> Popup Templates
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                    <p>Take a look at what we’ve been building in-house. Captivating & visually appealing popup templates, all built for you!</p>
                </div>
                <Row className="popup-templates-container">
                    <Col>
                        <GatsbyImage image={PopupIcon1} alt="" />
                    </Col>
                    <Col>
                        <GatsbyImage image={PopupIcon2} alt="" />
                    </Col>
                    <Col>
                        <GatsbyImage image={PopupIcon3} alt="" />
                    </Col>
                    <Col>
                        <GatsbyImage image={PopupIcon4} alt="" />
                    </Col>
                    <Col>
                        <GatsbyImage image={PopupIcon5} alt="" />
                    </Col>
                    <Col>
                        <GatsbyImage image={PopupIcon6} alt="" />
                    </Col>
                    <Col>
                        <GatsbyImage image={PopupIcon7} alt="" />
                    </Col>
                    <Col>
                        <GatsbyImage image={PopupIcon8} alt="" />
                    </Col>
                </Row>
                <div className="my-4 text-center">
                    <a className="btn btn-secondary" href="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" target="_blank">
                        More Templates <FaArrowRight />
                    </a>
                </div>
            </div>
            <div className="elementor-shape elementor-shape-bottom" data-negative="false">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path className="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                <path className="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                <path className="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                </svg>
            </div>
        </div>
    ) 
 }
export default HomePopupTemplates
